<template>
  <div class="user-info">
    <div >
      <img class="user-poster" :src="require('./background1.jpg')">
    </div>
    <div class="user-group">
      <van-form @submit="onSubmit">
        <van-field
            v-model="formData.name"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
            readonly
            name="phone"
            clickable
            :rules="[{ validator:validatorPhone,required: true, message: '填入正确的手机号'}]"
            label="手机号"
            placeholder="手机号"
            :value="formData.phone"
            @touchstart.native.stop="show = true"
        />
        <van-number-keyboard
            v-model="formData.phone"
            :show="show"
            :maxlength="11"
            @blur="show = false"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup,Button ,Form,Field,NumberKeyboard ,Popup,Picker,Notify,Search,RadioGroup, Radio,NoticeBar } from 'vant';
import {OfficeJson,OfficeList} from "@/api/dict";
export default {
  name: "register",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [NumberKeyboard.name]: NumberKeyboard,
    [Form.name]: Form,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Notify.name]: Notify,
    [Search.name]: Search,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field
  },
  data(){
    return {
      officeJson:OfficeJson,
      officeList:OfficeList,
      show:false,
      formData:{
        name:'',
        phone:undefined,
        userGroup:'100',
        infoId:undefined,
        officeId:undefined
      }
    }
  },
  mounted(){
    console.log(this.officeJson,this.officeList)
  },
  methods:{
    //提交
    onSubmit(){

    },
    // 验证手机号
    validatorPhone(val,rule){
      if(!(/^1[3456789]\d{9}$/.test(val))){
        return false;
      }
      return true;
    },
  }

}
</script>

<style lang="less" scoped>
.edit-btn{
  width: 100%;
  color: #fff;
  background-color: #1989fa;
  border: 1px solid #1989fa;
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  -webkit-appearance: none;
}
.user-poster{
  width: 100%;
  height: 100%;
}
.user-info{
  .info-btn{
    position: fixed;
    bottom: 0px;
    left: 0px;
  }

  .user-group{
    /* position: absolute;
     top: 300px;
     //top: 0px;*/
    width: calc(100vw);
    height: calc(100vh - 260px);
    background: white;
    z-index: 9;
  }


}
/*/deep/ .van-button{
  position: fixed;
  bottom: 0px;
  left: 0px;
}*/
</style>
